import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './characters.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import { Accordion, Card } from 'react-bootstrap';
import { IZZZCharacter } from '../../modules/common/model/graphql-types';
import {
  faAsterisk,
  faHandFist,
  faGavel,
  faFlask,
  faArrowUp,
  faArrowDown,
  faAnglesDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import lodash from 'lodash';
import './tier-list.scss';
import { ZZZCharacter } from '../../modules/zzz/common/components/zzz-character';

interface IZZZCharacterNodes {
  nodes: IZZZCharacter[];
}

interface IZZZCharacterEntry {
  allCharacters: IZZZCharacterNodes;
}

interface IProps {
  data: IZZZCharacterEntry;
}

const ZZZTierList: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'A',
            tooltip: 'A Rank',
            image: (
              <StaticImage
                src="../../images/zzz/icons/rarity_a.png"
                width={24}
                alt="A"
              />
            )
          },
          {
            value: 'S',
            tooltip: 'S Rank',
            image: (
              <StaticImage
                src="../../images/zzz/icons/rarity_s.png"
                width={24}
                alt="S"
              />
            )
          }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Electric',
            tooltip: 'Electric',
            image: (
              <StaticImage
                src="../../images/zzz/icons/ele_electric.png"
                width={24}
                alt="Electric"
              />
            )
          },
          {
            value: 'Ether',
            tooltip: 'Ether',
            image: (
              <StaticImage
                src="../../images/zzz/icons/ele_ether.png"
                width={24}
                alt="Ether"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/zzz/icons/ele_fire.png"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Frost',
            tooltip: 'Frost',
            image: (
              <StaticImage
                src="../../images/zzz/icons/ele_frost.png"
                width={24}
                alt="Frost"
              />
            )
          },
          {
            value: 'Ice',
            tooltip: 'Ice',
            image: (
              <StaticImage
                src="../../images/zzz/icons/ele_ice.png"
                width={24}
                alt="Ice"
              />
            )
          },
          {
            value: 'Physical',
            tooltip: 'Physical',
            image: (
              <StaticImage
                src="../../images/zzz/icons/ele_physical.png"
                width={24}
                alt="Physical"
              />
            )
          }
        ]
      },
      {
        key: 'style',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Anomaly',
            tooltip: 'Anomaly',
            image: (
              <StaticImage
                src="../../images/zzz/icons/style_anomaly.png"
                width={24}
                alt="Anomaly"
              />
            )
          },
          {
            value: 'Attack',
            tooltip: 'Attack',
            image: (
              <StaticImage
                src="../../images/zzz/icons/style_attack.png"
                width={24}
                alt="Attack"
              />
            )
          },
          {
            value: 'Defence',
            tooltip: 'Defence',
            image: (
              <StaticImage
                src="../../images/zzz/icons/style_defence.png"
                width={24}
                alt="Defence"
              />
            )
          },
          {
            value: 'Stun',
            tooltip: 'Stun',
            image: (
              <StaticImage
                src="../../images/zzz/icons/style_stun.png"
                width={24}
                alt="Stun"
              />
            )
          },
          {
            value: 'Support',
            tooltip: 'Support',
            image: (
              <StaticImage
                src="../../images/zzz/icons/style_support.png"
                width={24}
                alt="Support"
              />
            )
          }
        ]
      },
      {
        key: 'faction',
        type: 'dropdown',
        values: [
          { label: 'Any Faction', value: 'all' },
          {
            label: 'Belobog Heavy Industries',
            value: 'Belobog Heavy Industries'
          },
          {
            label: 'Criminal Investigation SRT',
            value: 'N.E.P.S.'
          },
          {
            label: 'Cunning Hares',
            value: 'Cunning Hares'
          },
          {
            label: 'OBOLS Squad',
            value: 'OBOLS Squad'
          },
          {
            label: 'Section 6',
            value: 'Section 6'
          },
          {
            label: 'Sons of Calydon',
            value: 'Sons of Calydon'
          },
          {
            label: 'Victoria Housekeeping Co.',
            value: 'Victoria Housekeeping Co.'
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.indexOf(activeFilters.rarity) > -1
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.style && activeFilters.style !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.style.toLowerCase() === activeFilters.style.toLowerCase()
        );
      }
      if (activeFilters.faction && activeFilters.faction !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.faction &&
            emp.faction.length > 0 &&
            emp.faction.indexOf(activeFilters.faction) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [{ value: 'shiyu', label: 'Shiyu' }];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout className={'generic-page zzz-tier-page'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/zzz/categories/category_tier.png"
            alt="Tier List"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero (ZZZ) Tier List</h1>
          <h2>
            Use our Tier List to discover the best A-Rank and S-Rank characters
            in Zenless Zone Zero (ZZZ).
          </h2>
          <p>
            Last updated: <strong>01/04/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="tier-list-page ">
        <SectionHeader title="Zenless Zone Zero Tier list (1.6)" />
        <p className="intro">
          Please keep in mind Zenless Zone Zero is a{' '}
          <strong>
            skill-based game and each character has a different playstyle
          </strong>
          , difficulty level and even special tricks and combos. Zenless Zone
          Zero is also a team based game and it’s only by combining both these
          factors to the highest level a character can reach optimal
          performance.
        </p>
        <Accordion className="tier-list-accordion ">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the Tier List</Accordion.Header>
            <Accordion.Body>
              <h6>Shiyu Defense</h6>
              <p>
                This tier list assesses characters based on their combat
                potential in Zenless Zone Zero’s current most demanding content,{' '}
                <strong>Shiyu Defense (SD)</strong>. SD is a mode similar to
                Genshin Impact’s Spiral Abyss or Honkai Star Rail’s Memory of
                Chaos and requires multiple teams, Disk Drives and good
                W-Engines. This mode offers a reasonable mix of Boss encounters
                (single target) and Elite packs (2-3 targets), allowing multiple
                characters to find a niche but in the end, there is a bias
                toward single target focused characters at the top-end and tier
                list ratings will reflect that.
              </p>
              <h6>Skill Ceilings</h6>
              <p>
                As an action-combat game, Zenless Zone Zero offers many combat
                options to players -{' '}
                <strong>
                  some of which are not easily executed but boost certain
                  characters' powers substantially
                </strong>
                . The Switch Cancel is an example of such a technique allowing
                you to switch out a character mid-attack to a new character
                while still fully executing the move you interrupted,
                essentially allowing you to attack with two characters at once.
                Such techniques won't be for everyone but a tier list must
                assess characters on their maximum potential and best teams.
                Each character is assessed with only the easiest and most
                powerful switch cancels included.{' '}
                <strong>
                  Techniques such as efficient Parrying, Rotations, Dodging and
                  Dodge countering are also expected.
                </strong>
              </p>
              <h6>About the ratings</h6>
              <p>
                To decide the ratings we use a combination of the data we gather
                (the Analytics you can find in the left menu), our own testing
                that's done inline with the criteria (check below) and the last
                element is community feedback we receive - either from Reddit,
                YouTube or our own Discord.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Criteria and roles</Accordion.Header>
            <Accordion.Body>
              <h6>Criteria</h6>
              <ul>
                <li>
                  All characters are assumed to be utilized in their full{' '}
                  <strong>best 3-man team</strong>, played with optimal
                  rotations without mistakes and using best Disk Drive main
                  sets.
                </li>
                <li>
                  All characters are using maximum level{' '}
                  <StaticImage
                    src="../../images/zzz/icons/rarity_s.png"
                    alt="S Rank"
                    className="faction-icon"
                  />{' '}
                  <strong>S-Rank</strong> Disk Drives.
                </li>
                <li>
                  All Disk Drive main stats are considered to be best in slot
                  for that characters build,
                </li>
                <li>
                  Characters are assessed with{' '}
                  <strong>good but not perfect sub-stats on Disk Drives</strong>
                  . Out of the 48 total sub stats possible on a fully built
                  characters Disk Drives, 20 will be evenly distributed between
                  all 10 different sub stats (2 subs in each). The remaining 28
                  sub stats are assigned to the characters best sub stats with a
                  restriction that out of these 28 no more than 12 can be
                  allocated in a single sub stat (E.g. 12 CRIT Rate, 12 CRIT
                  DMG, 4 ATK%).
                </li>
                <li>
                  <StaticImage
                    src="../../images/zzz/icons/rarity_s.png"
                    alt="S Rank"
                    className="faction-icon"
                  />{' '}
                  <strong>S-Rank</strong> Characters are all rated at{' '}
                  <strong>Mindscape 0</strong>.
                </li>
                <li>
                  <StaticImage
                    src="../../images/zzz/icons/rarity_a.png"
                    alt="A Rank"
                    className="faction-icon"
                  />{' '}
                  <strong>A-Rank</strong> Characters are all rated at{' '}
                  <strong>Mindscape 6</strong>.
                </li>
                <li>
                  All characters are assessed as if they have access to P5 of
                  all{' '}
                  <StaticImage
                    src="../../images/zzz/icons/rarity_a.png"
                    alt="A Rank"
                    className="faction-icon"
                  />{' '}
                  <strong>A-Rank</strong> and{' '}
                  <StaticImage
                    src="../../images/zzz/icons/rarity_b.png"
                    alt="B Rank"
                    className="faction-icon"
                  />{' '}
                  <strong>B-Rank</strong> W-Engine’s and P1 of the standard{' '}
                  <StaticImage
                    src="../../images/zzz/icons/rarity_s.png"
                    alt="S Rank"
                    className="faction-icon"
                  />{' '}
                  <strong>S-Rank</strong> W-Engine’s. The best out of all these
                  choices is used for their assessment.
                </li>
                <li>
                  All characters are considered to be the maximum possible level
                  with all Core Skill Enhancements upgraded to maximum rank,
                  with a maximum level Weapon and all abilities leveled to level
                  11/12.
                </li>
              </ul>
              <h6>Roles</h6>
              <p>
                We compare characters within their role - so DPS are compared to
                other DPS and the ratings are adjusted based on their
                performance within the role. Don't try to compare characters
                between roles, because their ratings mean different things.
              </p>
              <p>
                <strong className="dps">DPS</strong>
              </p>
              <p>
                DPS characters cover both the Attack unit class that focuses on
                dealing direct and immediate damage (usually through CRIT
                Scaling) and the Anomaly DPS counterparts who instead focus on
                scaling Anomaly Proficiency and Mastery. Their main 'job' is to
                kill stuff - as efficiently and fast as possible.
              </p>
              <p>Main criteria that impacts ratings for Pure DPS:</p>
              <ul>
                <li>
                  <strong>[Major Influence on Rating]</strong> Character damage
                  output within best possible team and ability to achieve max
                  star rating or better in Shiyu Defense,
                </li>
                <li>
                  <strong>[Medium Influence on Rating]</strong> Ease and
                  frequency of Anomaly application on one or multiple enemies.
                </li>
              </ul>
              <p>
                <strong className="debuffer">Stun</strong>
              </p>
              <p>
                Stun characters directly support the entire team and are
                generally made up of the Stun and Defence unit class. Their goal
                is to grant faster access to the incredibly potent Chain Attack
                window where many team compositions deal the majority of their
                damage. Stun characters are brought to the team to deal a
                tremendous amount of Daze, but also with the secondary role of
                providing buffs, debuffing enemies or contributing Anomaly
                Buildup.
              </p>
              <p>
                Stun characters can be played as on field characters taking up
                much of the teams combat time until the enemy is stunned in
                order to tag out and allow a fully charged damage dealer with
                all their resources ready to completely decimate the stunned
                enemy. They can also be played as a compliment to a damage
                dealer allowing for continual incredibly potent Defensive
                Assists (Parries) which allow for extreme Daze accumulation
                while also still giving time to a damage dealer on the team
                outside of the stun window. Sometimes teams and characters end
                up using a mix of these as well. If a character is focussed on
                parrying and building Daze as a priority you’ll find them in
                this category.
              </p>
              <p>Main criteria that impacts ratings for Stun:</p>
              <ul>
                <li>
                  <strong>[Major Influence on Rating]</strong> How much Daze a
                  character can deal to enemies in a variety of different
                  endgame situations.
                </li>
                <li>
                  <strong>[Medium Influence on Rating]</strong> Whether a
                  character grants any buffs or applies any debuffs and how
                  strong they are, the stronger the effect the more impact on
                  rating.
                </li>
                <li>
                  <strong>[Minor Influence on Rating]</strong> How much Anomaly
                  and/or Damage a character can accumulate/inflict on enemies
                  while still outputting their maximum Daze potential.
                </li>
              </ul>
              <p>
                <strong className="support">Support</strong>
              </p>
              <p>
                Support characters directly buff the team or debuff the enemy in
                a major way, enough that bringing them equals or outweighs the
                benefit of bringing another DPS or Daze-focused character. The
                best support characters take up as little time as possible while
                granting full access to their suite of buffs maximizing both
                damage/daze and field time for your Stun and DPS characters to
                do what they do best.
              </p>
              <p>Criteria that impact ratings for Supports:</p>
              <ul>
                <li>
                  <strong>[Major Influence on Rating]</strong> How powerful the
                  support characters buffs are and how many other characters
                  they can directly benefit. The stronger the buffs and more
                  universal the better the rating.
                </li>
                <li>
                  <strong>[Medium Influence on Rating]</strong> How much or
                  little field time the support character requires in order to
                  perform their role.
                </li>
                <li>
                  <strong>[Minor Influence on Rating]</strong> How much Anomaly,
                  Daze and Damage a character can accumulate/inflict on enemies
                  while still fulfilling their role as a support.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Tags <span className="tag new">Updated!</span>
            </Accordion.Header>
            <Accordion.Body>
              <h6>Special tags</h6>
              <p>
                Tags represent the most defining features of a character's kit
                and what archetype they may belong to.
              </p>
              <ul>
                <li>
                  <strong className="archetype">Aftershock</strong> - Characters
                  with this archetype tag possess an element of Aftershock
                  damage in their kit and or synergize with characters that do.
                  Characters with this tag are generally effective when played
                  together.
                </li>
                <li>
                  <strong className="archetype">Combo</strong> - the character's
                  gameplay contains one or multiple combos outside of the usual
                  button mashing playstyle that should be followed in order to
                  maximize their contributions in combat.
                </li>
                <li>
                  <strong className="archetype">Charge</strong> - the
                  character's kit contains one or many Charged Attacks they must
                  perform in order to unlock their kit’s full potential.
                </li>
                <li>
                  <strong className="archetype">Minion</strong> - the
                  character's kit contains minions that linger on the field and
                  apply debuffs, buffs or deal damage and Daze. Minions may or
                  may not need to be resummoned periodically.
                </li>
                <li>
                  <strong className="archetype">Sub-DPS</strong> - the
                  character's kit does not lend itself toward being the sole
                  damage dealer of the team or are much stronger when paired
                  with another damaging character. These characters are usually
                  capable of fully utilizing their kit with very little field
                  time needing another character to fill in the blank spot.
                </li>
                <li>
                  <strong className="archetype">Quick Swap</strong> - Character
                  needs to swap in and out of the field in the middle of their
                  or another character's rotations repeatedly in order to
                  maximize their potential or mitigate the time spent casting
                  very long animations, which defines a part of their optimized
                  playstyle. Without Quick Swap involved, the character may feel
                  clunkier and/or perform worse.
                </li>
                <li>
                  <strong className="archetype">Hybrid (Stun)</strong> - the
                  character's kit allows them to fulfill multiple roles in the
                  team. In this tag's case, the additional role the character
                  can perform well at is Stun.
                </li>
                <li>
                  <strong className="archetype">Hybrid (Support)</strong> - the
                  character's kit allows them to fulfill multiple roles in the
                  team. In this tag's case, the additional role the character
                  can perform well at is Support.
                </li>
                <li>
                  <strong className="status">Shock</strong> - the character's
                  kit is focused on dealing damage through, relies on or buffs
                  Shock damage. The characters with this tag require Shock to be
                  present on enemies in order for their kit to be fully
                  utilized.
                </li>
                <li>
                  <strong className="status">Assault</strong> - the character's
                  kit is focused on dealing damage through, relies on or buffs
                  Assault damage. The characters with this tag are focused on
                  inflicting Assault on enemies as often as possible.
                </li>
                <li>
                  <strong className="status">Burn</strong> - the character's kit
                  is focused on dealing damage through, relies on or buffs Burn
                  damage.
                </li>
                <li>
                  <strong className="status">Shatter</strong> - the character's
                  kit is focused on dealing damage through, relies on or buffs
                  Shatter damage.
                </li>
                <li>
                  <strong className="status">Disorder</strong> - the character's
                  kit is focused on dealing damage through, relies on or buffs
                  Disorder damage.
                </li>
                <li>
                  <strong className="pro">Buff</strong> - the character can
                  apply various Buffs to help their teammates.
                </li>
                <li>
                  <strong className="pro">Debuff</strong> - Character has a
                  debuff which causes a status to be inflicted on enemies, which
                  can increase your whole party's damage against that specific
                  target in various ways or may hinder their mechanics, damage
                  or movement. The main examples of this are stat decreases such
                  as DEF shred, RES shred, DMG against specific target increased
                  and enemy DMG down. Debuffs that exclusively apply to the
                  party member that applies them are not considered, they must
                  benefit other party members.
                </li>
                <li>
                  <strong className="pro">Heal</strong> - Character can restore
                  HP to themselves and/or the team, contributing to sustaining
                  your party, particularly over longer and more difficult
                  fights.
                </li>
                <li>
                  <strong className="pro">Shield</strong> - the Character can
                  apply Shields to themselves and or teammates to absorb damage.
                </li>
                <li>
                  <strong className="special">Expert</strong> - Character can be
                  considered one placement higher than their default tier list
                  ranking, but has an optimal playstyle that needs to be
                  mastered to qualify for that higher rating. Characters only
                  obtain this tag if the expert playstyle creates a significant
                  discrepancy in power compared to their baseline playstyle,
                  more so than characters without the tag.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Changelog
              <span className="tag new">Updated!</span>
            </Accordion.Header>
            <Accordion.Body>
              <h6>02/04/2025</h6>
              <p>
                ZZZ's meta has been shifting quite a bit in recent times, and
                with Trigger's release Stun units are finally getting a shake up
                as well. Outside of a few exceptions, most DPS and Support
                Agents tier list placements aren’t moving as a result of
                Triggers release.
              </p>
              <p>
                In addition to Trigger’s addition and the adjustment of the Stun
                category we’re adding a new unit tags and revising some old
                ones. The new addition is:
              </p>
              <ul>
                <li>
                  <strong>Heal:</strong> Character can restore HP to themselves
                  and/or the team, contributing to sustaining your party,
                  particularly over longer and more difficult fights.
                </li>
              </ul>
              <p>The updated tags are:</p>
              <ul>
                <li>
                  <strong>Debuff:</strong> Character can inflict debuffs to
                  enemies, causing a variety of effects that can benefit your
                  party. Debuffs that only benefit the applier of the debuff and
                  not the full squad are not considered.
                </li>
                <li>
                  <strong>Expert:</strong> Character can be considered one
                  placement higher than their default tier list ranking, but has
                  an optimal playstyle that needs to be mastered to qualify for
                  that higher rating. This tag is only applied to characters
                  where the harder play style creates a significant discrepancy
                  in performance compared to the baseline playstyle.
                </li>
                <li>
                  <strong>Quickswap:</strong> Character’s kit benefits from
                  swapping in and out of the field during their or other
                  character’s rotations to maximize their potential and/or
                  potentially mitigate long animations. Characters with this tag
                  may feel clunkier and/or perform worse without doing so.
                </li>
              </ul>
              <p>And here are the changes to the tier list:</p>
              <ul>
                <li>
                  <strong>[Trigger]</strong> added as T0 Stun. Trigger is one of
                  the most impressive Stun Agents the game has yet seen -
                  possibly the most impressive. She has universal Support
                  potential via a 35% Stun DMG Multiplier increase that has
                  permanent uptime and operates off-field which gives freedom to
                  a bunch of DPS characters she can pair with, not to mention
                  her ease of use — possibly the easiest Stun Agent we've had
                  yet. Even without big vertical upgrades involved, Trigger is
                  undoubtedly T0 because her kit gives her a lot of team
                  versatility, Daze and buffing potential, possibly the most of
                  any Stun Agent which is impressive.
                </li>
                <li>
                  <strong>[Anby: Soldier 0]</strong> added to Watchlist. SAnby
                  gains more than most through the inclusion of Trigger in her
                  teams, giving her enough of a boost we’re now considering her
                  for T0. With that said Miyabi continues to put up one of the
                  most dominating performances of any character in a Gacha game,
                  for this reason we’re watchlisting her first and will see how
                  she performs moving forward before raising her.
                </li>
                <li>
                  <strong>[Harumasa]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1.5. Based on most recent performances and how players are
                  playing him, Harumasa's baseline rating is too low compared to
                  units he shares a tier with and who are directly above him
                  after the recent DPS category adjustment. We’re raising his
                  baseline rating to T1.5 and as a result his new expert rating
                  will be T1 putting him on equal footing with Zhu Yuan and Jane
                  doe in the hands of experienced players.
                </li>
                <li>
                  <strong>[Qingyi]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Qingyi is our first character to get out of T0 in a very
                  long time, however enough time has passed, and enough DPS
                  characters that don't synergize particularly well with her as
                  well as stronger Stun Agent options have taken the spot she
                  usually takes. Don't get us wrong, it's not because Qingyi
                  isn't in T0 that she's by any accounts a bad Agent — she
                  retains big team flexibility and performs notably well in S0
                  Anby teams more recently, plus she's still the most desirable
                  with Harumasa and Zhu Yuan over any other Stun options. She's
                  comfortably in T0.5, no questions asked. However, Qingyi
                  doesn't synergize with any of the top DPS Agents anymore other
                  than S0 Anby somewhat (and S0 Anby has Trigger and Pulchra to
                  outperform Qingyi in most cases anyway). Miyabi, Evelyn,
                  Yanagi and Burnice don't make particularly good use of her and
                  that's all our T0-T0.5 DPS agents other than S0 Anby who we
                  already discussed, so we believe Qingyi moving down to T0.5 is
                  warranted. However with her dropping down, a lot of Stun
                  Agents will drop down a tier as well to illustrate how much
                  more powerful Qingyi is than them. This starts with:
                </li>
                <li>
                  <strong>[Lycaon]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. Not much to say here that wasn't already said in Qingyi's
                  entry, however we'll add part of this change is warranted
                  because Lycaon only really serves as the best F2P option to
                  Miyabi and Ellen which doesn't make him particularly flexible,
                  due to Miyabi's insane variety of team options and Ellen being
                  nowadays quite a few tiers down the top contenders. This
                  justifies him moving to T1 further, however he's still a very
                  good Agent to own and build no matter what, and with future
                  Ice agents that want a Stun character on the team you can rest
                  assured Lycaon will always be a good choice for them.
                </li>
                <li>
                  <strong>[Koleda]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Koleda has one main saving grace and that is that she's
                  the best F2P Stun choice for Evelyn, as she increases her
                  Chain Attack damage so she should be stable in T1.5 because
                  Evelyn is an amazing DPS Agent, however she dropped down due
                  to Lycaon's presence in T1 being much more felt in the current
                  meta than Koleda.
                </li>
                <li>
                  <strong>[Anby]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T2. Let's make something very clear: Anby's performance, for a
                  free A-rank Stun Agent you get at the start of the game that
                  doesn't have any special qualities, is actually impressive.
                  Even though her kit is pure damage and Daze and nothing else,
                  she's really good at those two things when paired with
                  characters with downtimes such as Zhu Yuan and Harumasa, so
                  she doesn't warrant anything below T2. However, she's overall
                  weaker than the choices above her as they all provide
                  something special to the team and Anby does not.
                </li>
                <li>
                  <strong>[Pulchra]</strong> is receiving no changes in
                  placement. We did consider her dropping down along with
                  Koleda, however at M6 she has a very desirable all-type 30%
                  DMG debuff which boosts the whole team's damage by a good
                  amount on top of her off-field damage and Daze, which gives
                  her good synergies (especially with S0 Anby). This warrants
                  her staying in T1.
                </li>
              </ul>
              <p>Now let's tackle the Tag Changes: </p>
              <ul>
                <li>
                  [Anby: Soldier 0] Debuff tag added. Pertaining to her
                  Additional Ability effect (her Core Passive isn't a
                  team-applicable debuff so it doesn't warrant the tag).{' '}
                </li>
                <li>
                  [Piper] Buff tag added. Pertaining to her Additional Ability
                  effect.{' '}
                </li>
                <li>
                  [Grace] Debuff tag added. Pertaining to her Additional Ability
                  effect.{' '}
                </li>
                <li>[Corin] Debuff tag added. Pertaining to her M2. </li>
                <li>
                  [Anton] Shield, Buff tags added. Pertaining to his M2 and M4.{' '}
                </li>
                <li>
                  [Lighter] Buff tag added. Pertaining to his Additional Ability
                  effect.{' '}
                </li>
                <li>[Pulchra] Debuff tag added. Pertaining to her M6. </li>
                <li>
                  [Koleda] Debuff tag added. Pertaining to her Additional
                  Ability effect.{' '}
                </li>
                <li>
                  [Astra Yao] Heal tag added. Pertaining to her Ultimate. We
                  judged it an essential part of her kit to where the tag is
                  worth making just for her, as well as any future characters if
                  they do provide healing as well.{' '}
                </li>
                <li>
                  [Nicole] Buff, Quickswap tags added. Pertaining to her
                  Additional Ability effect, her M6 and her playstyle. Nicole
                  enjoys switching in and out in the middle of battle, including
                  during a Stun window, to fully capitalize on her DEF shred
                  effect for your DPS Agents, which forces you to alter your
                  rotation and warrants the tag.{' '}
                </li>
                <li>[Soukaku] Debuff tag added. Pertaining to her M4. </li>
                <li>
                  [Seth] Debuff tag added. Pertaining to his Additional Ability
                  effect.{' '}
                </li>
                <li>[Ben] Debuff tag added. Pertaining to his M1.</li>
              </ul>
              <h6>12/03/2025</h6>
              <p>
                With the release of S0 Anby, and an accumulation of data over a
                number of phases since our last update, we’re making some
                substantial changes to the tier list - specifically the Damage
                category. In recent phases of Shiyu Defense a divide between
                damaging characters currently in the Apex tier has emerged. To
                address this we’re introducing Tier 4, re-organizing the tiers,
                and adjusting the ratings of some characters.
              </p>
              <p>
                After the release of Miyabi, Evelyn and now S0 Anby it’s clear
                the bar for damage dealers has been set higher. To reflect this
                reality, we’ve had adjust the rating of some older damage
                dealers down the tier list. With that said these characters are
                by no means bad, or unable to clear endgame content, they simply
                don’t destroy it quite at the same pace as the most recent
                additions to the damage dealing roster. To ensure the tier list
                still makes sense and each character is in a tier with peers
                that reflect a similar power level we’ve introduced an
                additional Tier (Tier 4) and shuffled the majority of characters
                down the tier list. In addition to this change we’ve also
                adjusted the meta line indicating what is a niche character and
                what is a meta character from sitting between Tier 1.5 and Tier
                2 to sit between Tier 2 and Tier 3 instead.
              </p>
              <p>
                The goal of all these changes isn’t to “drop every character on
                the tier list” it’s to ensure that each character is grouped
                with other characters of a similar power level something the
                previous iteration of the tier list wasn’t quite achieving.
              </p>
              <p>
                Outside of this here are a few characters movements we feel
                deserve their own explanation:
              </p>
              <ul>
                <li>
                  <strong>[Zhu Yuan]</strong> & <strong>[Jane doe]</strong>.
                  Both remain powerful Agents but are struggling to keep up with
                  other characters residing in the Apex Tier in terms of clear
                  times in the latest phases of Shiyu Defense. If and when the
                  encounters favor them more and they’re able to make a comeback
                  we’ll return them to the highest tiers.
                </li>
                <li>
                  <strong>[Piper]</strong>. She is one of the few units we’re
                  not shuffling down as a result of this change as she’s put out
                  some incredibly convincing results in more recent Shiyu
                  phases. We’re keeping her in Tier 1 for now but will keep an
                  eye on her to see if she can maintain her performance.
                </li>
                <li>
                  <strong>[Qingyi]</strong>. We’re placing Qingyi on the
                  watchlist for now. She hasn’t been performing at the level we
                  expect of a Tier 0 stunner and isn’t as meta relevant when it
                  comes to forming teams with Apex Damage dealers as some of her
                  peers. With that said she can be played alongside S0 Anby, so
                  we’re keeping an eye on her for now and seeing how she
                  performs in the coming phase.
                </li>
              </ul>
              <h6>12/02/2025</h6>
              <ul>
                <li>
                  <strong>[Evelyn]</strong> has been added to the tier list,
                </li>
                <ul>
                  <li>
                    We’re placing Evelyn in T0.5 initially with the Expert Tag.
                    This Tag indicates that in the hands of a beginner or
                    “average” player she sits around the T0.5 level, but in the
                    hands of an expert she performs at a T0 level, equivalent to
                    Miyabi. We feel this placement is a reasonable starting
                    point for her considering her difficulty - some consider her
                    incredibly simple and easy to play while others find her
                    more challenging. We’ll be monitoring how the community
                    receives Evelyn - if she’s easier to grasp and play at the
                    highest level than expected we’ll remove the Expert tag from
                    her and raise her to T0, but if she turns out to be just as
                    hard as some fear, we’ll keep her where she is for now.
                  </li>
                </ul>
                <li>
                  With the arrival of Evelyn and the upcoming release of Anby:
                  Silver Soldier we expect shifts to occur over the next few
                  Shiyu Defense phases in the DPS Category of the Tier List. We
                  are aware that some Apex DPS characters aren’t performing
                  quite as well as they were in the past or to the level some of
                  their peers are currently at. With that said we’ll be holding
                  off on any major changes until the release of Anby: Silver
                  Soldier to see where the meta settles. We already watchlisted
                  Jane Doe because of that, because of all T0.5 characters she
                  had the roughest performance drop recently.
                </li>
              </ul>
              <h6>22/01/2025</h6>
              <ul>
                <li>
                  <strong>[Astra Yao]</strong> has been added to the tier list,
                </li>
                <li>
                  Based on the previous feedback of the major rework to our
                  Shiyu Defense Tier list, alongside the latest data performance
                  we’re making a few adjustments to some character’s rankings in
                  addition to the placement of Astra Yao on the tier list.
                </li>
                <li>
                  <strong>[Piper]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Piper has continued to demonstrate success in Shiyu as an
                  accessible Anomaly option with strong team options. She was
                  ranked too low as a result of the merge, we’re fixing that
                  with this update.
                </li>
                <li>
                  <strong>[Soldier 11]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Based on feedback received, new/Additional clear data
                  and further testing, the general consensus is that Soldier 11
                  isn’t currently performing at Ellen’s level. As a result we’re
                  lowering Soldier 11’s tier by one rank for now.
                </li>
                <li>
                  <strong>[Harumasa]</strong>. Harumasa is a character with
                  great potential when played optimally, something that isn’t
                  for everyone. We’ve ranked Harumasa under the assumption that
                  he would be played according to his strongest combos, but this
                  isn’t always realistic due to combat scenarios or player
                  skill/playstyle. In recognition of this, we’re introducing the
                  new Expert Tag to the tier list to both recognize his
                  potential but also highlight his higher skill requirements.
                  For those who can play him to his full potential his effective
                  position on the tier list is unchanged as per the Expert tags
                  description, for those who can’t his new placement applies.
                </li>
                <li>
                  <strong>Expert Tag</strong>: Characters tagged with the Expert
                  Tag can be considered one placement higher than their tier
                  list ranking, but you need to master their advanced/harder
                  playstyle for them to qualify for that higher rating.
                </li>
              </ul>
              <h6>18/12/2024</h6>
              <ul>
                <li>
                  <strong>[Miyabi]</strong> has been added to the tier list,
                </li>
                <li>
                  <strong>[Harumasa]</strong> has been added to the tier list,
                </li>
                <li>
                  <strong>
                    We have combined the Pure DPS and Anomaly DPS columns into
                    one column - DPS
                  </strong>
                  . We’ve intended on doing this for a while now but the release
                  of Miyabi solidified the decision. The Anomaly Category was
                  created under the premise that Anomaly characters would play
                  vastly different to Attack characters much like Grace is
                  different than a character like Soldier 11. This is not how
                  things played out post release. A lot of Anomaly characters
                  function as direct damage dealers akin to Attack characters
                  with the main distinction being one stacks Anomaly related
                  stats and the other stacks CRIT but now with Miyabi even that
                  line is blurred. In ZZZ under the current meta we feel Attack
                  and Anomaly characters can directly be compared in terms of
                  performance, their roles are not different enough to keep them
                  separate and so we have combined them. As a result of this
                  change some characters ranking have been updated to make
                  sense. As always we’ll be reading your feedback,
                </li>
                <li>
                  We have added the <strong>meta-lines</strong> that are already
                  present in our other tier lists. They split the tier list into
                  3 groups, showcasing roughly how they are currently used in
                  the meta: Apex (best of the best), Meta (great characters) and
                  Niche (characters with issues that require more work to
                  achieve good results). There's no Forgotten Ones group yet as
                  the meta isn't in as bad state as HSR and everyone is viable -
                  more or less,
                </li>
                <li>
                  We have added a new tag - <strong>Quick Swap</strong>. The tag
                  highlights characters that benefit greatly from swapping to
                  another character during one or many of their abilities that
                  have incredibly long animations. This allows this downside to
                  be mitigated,
                </li>
                <li>
                  We have changed the color of the Anomaly related tags like
                  Shock, Burn and Disorder to make them stand out a bit more.
                </li>
              </ul>
              <h6>27/11/2024</h6>
              <ul>
                <li>
                  <strong>[Lighter]</strong> has been added to the tier list.
                </li>
                <li>
                  <strong>[Rina]</strong> T1.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Rina is proving to be a popular choice for Yanagi teams
                  and has a solid position in a top endgame content meta team.
                  We’re raising her up one tier for now, but she might need to
                  go up again in the next update.
                </li>
                <li>
                  <strong>[Grace]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1.5. Despite Anomaly’s power level as an Archetype surging as
                  a whole, Grace is seeing decreased play and success in endgame
                  content. Yanagi’s release has also had a significant impact on
                  her Electric damage niche as she does more Anomaly damage, can
                  deal it immediately and has far more powerful team
                  compositions. As such we’re lowering Grace to reflect her
                  current status in the meta.
                </li>
              </ul>
              <h6>06/11/2024</h6>
              <ul>
                <li>
                  <strong>[Yanagi]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                In addition to placing Yanagi on the tier list we're following
                through with adjusting the placement of the characters we
                previously placed on the watch list.
              </p>
              <ul>
                <li>
                  All "DPS" characters were moved down 1 rank to reflect the gap
                  in power between direct damage dealers and and the ever more
                  powerful Anomaly Archetype.
                </li>
                <li>
                  Soukaku moved down one rank - her position in the Meta is no
                  longer as prominant due to a combination of stronger options
                  being released and a shift in team building toward Anomaly and
                  double damage.
                </li>
              </ul>
              <h6>16/10/2024</h6>
              <ul>
                <li>
                  <strong>[Burnice]</strong> has been added to the tier list.
                </li>
              </ul>
              <p>
                As Patch 1.2 draws to a close by popular demand we’ve made the
                decision to rework our Tier list to include{' '}
                <StaticImage
                  src="../../images/zzz/icons/rarity_a.png"
                  alt="A Rank"
                  className="faction-icon"
                />{' '}
                Agents at M6 instead of M0. This change impacts all Agent
                categories and alters characters' power levels relative to each
                other.{' '}
                <StaticImage
                  src="../../images/zzz/icons/rarity_a.png"
                  alt="A Rank"
                  className="faction-icon"
                />{' '}
                Agents with strong Mindscapes will see an uptick in rating
                compared to{' '}
                <StaticImage
                  src="../../images/zzz/icons/rarity_a.png"
                  alt="A Rank"
                  className="faction-icon"
                />{' '}
                with weaker Mindscapes for their role and{' '}
                <StaticImage
                  src="../../images/zzz/icons/rarity_s.png"
                  alt="S Rank"
                  className="faction-icon"
                />{' '}
                characters without strong meta impact.
              </p>
              <p>
                <strong>
                  The toggle to switch between M0 and M6 for{' '}
                  <StaticImage
                    src="../../images/zzz/icons/rarity_a.png"
                    alt="A Rank"
                    className="faction-icon"
                  />{' '}
                  Agents will be added soon - we are still finalizing a few
                  things!
                </strong>
              </p>
              <p>
                On top of the transition from M0 to M6 we’re also making
                adjustments based on recent endgame meta developments stemming
                from the release of Jane, Caesar and now Burnice. Each of these
                characters impact the meta in their own way but share the fact
                they all display a large power increase compared to their peers
                in their respective top teams and niches resulting in some
                additional adjustments being needed.
              </p>
              <p>
                Since 1.0 Anomaly/Disorder teams have received continual support
                and gained significant meta relevance taking the top spot in
                many endgame encounters convincingly. With the release of
                Burnice, Anomaly transforms once again seeing new top Anomaly
                teams and power combos emerge, pushing the archetype further but
                also adjusting other characters meta relevance as a result. With
                Yanagi - another Anomaly character - slated for release in 1.3
                we don’t expect Anomaly’s power gains to stop any time soon.
              </p>
              <p>
                The tier list rework includes consideration for each of the
                points listed above alongside our usual updates based on
                performance, feedback and continued testing. In conclusion:
              </p>
              <ul>
                <li>
                  <StaticImage
                    src="../../images/zzz/icons/rarity_a.png"
                    alt="A Rank"
                    className="faction-icon"
                  />{' '}
                  Agents with weaker Mindscapes will not see as large a move as
                  Agents with them.
                </li>
                <li>
                  Some{' '}
                  <StaticImage
                    src="../../images/zzz/icons/rarity_s.png"
                    alt="S Rank"
                    className="faction-icon"
                  />{' '}
                  Agents will move down the tier list or join{' '}
                  <StaticImage
                    src="../../images/zzz/icons/rarity_a.png"
                    alt="A Rank"
                    className="faction-icon"
                  />{' '}
                  Agents due to the average power level of many of the lists
                  characters increasing
                </li>
                <li>
                  Characters that directly benefit the strongest Anomaly meta
                  characters, will retain or achieve high positions on the tier
                  list.
                </li>
                <li>
                  Characters that belong to a niche with increased competition
                  or teams with power below the new bar set will see their
                  placements impacted.
                </li>
              </ul>
              <p>
                With the newly bolstered Anomaly archetype raising the bar in
                terms of top-end power, we’re currently keeping an eye on all
                Agents in the DPS category (and dedicated supports for them like
                Soukaku) to see how their average performance fares against
                similar Anomaly teams. If 'pure' DPS characters are too far
                behind the bar Anomaly sets we may adjust the entire category
                down to better reflect the state of endgame -{' '}
                <strong>
                  that's why we have added the 'watchlist' marker to Ellen, Zhu
                  Yuan and Soukaku
                </strong>
                , but in reality everyone in the column should have it. Please
                send us your feedback about the Anomaly vs Pure DPS 'battle' and
                who you feel is stronger!
              </p>
              <h6>25/09/2024</h6>
              <ul>
                <li>
                  <strong>[Caesar]</strong> has been added to the tier list.
                </li>
                <li>
                  <strong>[Nicole]</strong> & <strong>[Soukaku]</strong> T0{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T0.5. Well, it was fun while it lasted, but there's a new
                  Queen of Supports in town - Caesar. And sadly, at M0, neither
                  Soukaku nor Nicole can perform on Caesar's level, so we
                  dropped both half a tier.
                </li>
                <li>
                  We are nearly done with testing M6{' '}
                  <StaticImage
                    src="../../images/zzz/icons/rarity_a.png"
                    alt="A Rank"
                    className="faction-icon"
                  />{' '}
                  <strong>A-Rank</strong> characters and within the next few
                  weeks the tier list will be updated with a toggle that allows
                  you to check how the{' '}
                  <StaticImage
                    src="../../images/zzz/icons/rarity_a.png"
                    alt="A Rank"
                    className="faction-icon"
                  />{' '}
                  <strong>A-Rank</strong> characters perform at M0 and M6 (and
                  someone might jump back to the spot taken by Caesar today).
                </li>
              </ul>
              <h6>04/09/2024</h6>
              <ul>
                <li>
                  <strong>[Jane Doe]</strong> and <strong>[Seth]</strong> have
                  been added to the tier list.
                </li>
                <li>
                  <strong>[Nicole]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Nicole has proven herself a force to be reckoned with in
                  Shiyu defense thanks to her incredible synergy with Zhu Yuan.
                  Nicole boasts some of the strongest performances and highest
                  play rates and holds similar meta relevance to Soukaku. For
                  this reason we feel it's appropriate to pair the two in the
                  same tier and will be raising her rating for now.
                </li>
              </ul>
              <p>
                When characters perform unexpectedly — either exceeding or
                falling short of expectations—our team places them on an
                internal "watch list." This approach helps us avoid hasty tier
                adjustments and ensures that any changes we make are
                well-considered and accurate. Here’s a transparent look at the
                characters we’re currently monitoring and the reasons behind it.
                We welcome your feedback! (We have also marked those characters
                with an eye symbol to spot them easier on the tier list).
              </p>
              <ul>
                <li>
                  <strong>[Nekomata]</strong> hasn’t performed quite at the
                  level we’ve expected of her in recent Shiyu Defenses when
                  compared to Soldier 11 she shares her tier placement with.
                  We’re not making any changes yet but are keeping an eye on her
                  performance as we move forward to see if it improves or if it
                  goes in the opposite direction as a result of the release of
                  the new Physical damage dealing powerhouse Jane Doe.
                </li>
              </ul>
              <h6>14/08/2024</h6>
              <p>
                The second Shiyu Defense phase is well under way and more
                players have far better Disc Drives and higher level Agents and
                W-Engines to work with. When updating our tier lists we like to
                align them with the release of endgame character play rates and
                score data to ensure what we’ve observed in the community and
                tested ourselves aligns with the bigger picture. As we’ve now
                got our first batch of proper endgame data with players close to
                or at max level with reasonable Disc Drives we feel it necessary
                to make a few changes to the tier list.
              </p>
              <ul>
                <li>
                  <strong>[Nicole]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. Nicole is currently one of the most dominant characters
                  in Shiyu Defense, thanks to her incredible synergy with Zhu
                  Yuan - one of the strongest damage dealers in the game. And
                  with the release of Qingyi and replacement of Anby this
                  powerhouse team gets even stronger, further boosting Nicole’s
                  power level and meta relevancy. On top of this Nicole also has
                  a number of other lower power team compositions she see’s
                  regular use in such as Nekomata or Billy teams making her a
                  flexible unit if need be as well. We feel even at Mindscape 0
                  Nicole has proven herself to be one of the top supports in the
                  meta and are raising her up a tier.
                </li>
                <li>
                  <strong>[Rina]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowDown}
                    width="18"
                    className="arrow-red"
                  />{' '}
                  T1. Rina currently feels like she’s falling short of her
                  placement on the tier list when compared to the power houses
                  of Soukaku and Nicole who each have a solid spot in the two
                  most powerful meta teams (Ellen and Zhu Yuan teams). Rina can
                  be played alongside Soukaku and Ellen in double support
                  set-ups or as a substitute for Soukaku if desired but neither
                  option is currently the go to or highest performing variant of
                  this team. Alternatively, Rina can be played in Anomaly based
                  teams as well, but unfortunately these teams haven’t quite
                  seen the same success as the two aforementioned powerhouses
                  just yet. We’re downgrading Rina from tier 0.5 to tier 1 for
                  now given the sheer dominance of Nicole and Soukaku but are
                  keeping a close eye on her as more anomaly and electric
                  characters are released.
                </li>
                <li>
                  <strong>Stun Characters:</strong> With the release of Qingyi
                  we thought about making large adjustments to the Stun
                  category. Thanks to her easy to activate additional ability
                  requirements and her completely generic Stun damage
                  amplification she can be played in literally any combat based
                  team to very great effect. For these reasons we considered
                  placing her as the sole T0 character above Lycaon as even in
                  Ice teams she isn’t too far behind his damage amplification.
                  For now though as we expect both Lycaon and Qingyi to achieve
                  incredible Shiyu results and as Lycaon does still have a small
                  edge in one of the two best teams we’re placing Qingyi
                  alongside him but keeping an eye on the situation.
                </li>
                <li>
                  <strong>M0/M6</strong>: Considering that the banners in ZZZ
                  feature only 2 A-Rank characters compared to the usual 3, it's
                  easier to obtain their dupes. Because of that we're planning
                  to introduce a toggle switch similar to the one we have in
                  Honkai: Star Rail tier list that allows you to switch between
                  A-Rank characters at M0 and M6. This feature will be
                  introduced most likely with Jane Doe release, so in a few
                  weeks - as we need to test all the characters at M6 now to
                  judge their power level.
                </li>
              </ul>
              <h6>11/08/2024</h6>
              <ul>
                <li>
                  <strong>[Billy]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T2. Billy’s kit has many tricks and a fair few obscure
                  interactions which aren’t immediately apparent to most who try
                  him out. Over time players have made big progress with Billy’s
                  combos and rotations the biggest discovery of which is the
                  ability to execute his Ultimate and EX’s in quick succession
                  while still being considered to be in the crouching state.
                  This allows him to apply his up to 50% damage bonus core
                  passive to his hardest hitting abilities. This optimization
                  alongside a number of other smaller ones makes Billy feel much
                  stronger to play and improves his damage output significantly.
                  Because of this we feel comfortable moving Billy Tier 2
                  alongside Corin and Anton for now. Shout out to
                  SUPERCOW/Donovahkiin for their work on testing out Billy’s
                  kit.
                </li>
              </ul>
              <h6>08/08/2024</h6>
              <ul>
                <li>
                  <strong>[Ben]</strong> has been moved from Stun to Support. In
                  the endgame the community and our team are finding more
                  success with Ben in the support role compared to the Stunning
                  role. Ben's stunning capabilities, while passable if you have
                  nothing else, pale in comparison to characters dedicated to
                  the role such as Anby, Lycaon, Koleda or the upcoming Qingyi.
                  Alternatively the combination of Ben's Shields, CRIT RATE buff
                  and low field time requirements make him a reasonable F2P
                  supportive choice. On top of this Ben also sees some of the
                  most use paired with Koleda - again in the supportive role
                  offering all the aforementioned benefits on top of also
                  providing kit enhancements to Koleda herself. For these
                  reasons we’re moving Ben over to the Support category for now
                  and updating the build on his profile page.
                </li>
              </ul>
              <h6>24/07/2024</h6>
              <ul>
                <li>
                  <strong>[Zhu Yuan]</strong> has been added to the tier list.
                </li>
                <li>
                  <strong>[Koleda]</strong> T1{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0.5. We've tested her and the difference between the
                  pre-hotfix version and the fixed one is like night and day and
                  Koleda now is closer to Lycaon than Anby when it comes to the
                  ability to Stun enemies. So we're raising her up one tier.
                </li>
                <li>
                  We're also retesting <strong>[Nicole]</strong> as now with Zhu
                  Yuan release, she finally has a team she can call home and her
                  rating will be revaluated in a few days.
                </li>
              </ul>
              <h6>18/07/2024</h6>
              <p>
                We are currently looking into <strong>[Koleda]</strong> as
                apparently she was bugged since release and her Core Passive
                decreased the amount of Daze done instead of increasing it - and
                the difference is quite big: 30-60%. This bug has been fixed in
                a hotfix 3 days ago and once we're done with testing her, we
                will revaluate her position on the tier list.
              </p>
              <h6>13/07/2024</h6>
              <ul>
                <li>
                  <strong>[Corin]</strong> T3{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T2. After completing Corin’s damage calculations and testing
                  her, it’s become clear she has some of the highest burst
                  damage potential in the game during enemy Stun window, but
                  only when she has her Ultimate and EX Special Attack
                  available. To offset this strength however, she has one of, if
                  not the worst standard rotation, in the game - being so bad
                  fully executing it without interruption is a serious challenge
                  and even if you do, it’s damage is poor. Not using Corin’s
                  standard rotation should be the go-to move but there are some
                  issues with avoiding it completely.
                </li>
                <ul>
                  <li>
                    Corin’s EX Special as mentioned earlier is essential to her
                    Burst rotation - without it her damage plummets and
                    unfortunately it has a very high energy cost of 90. Most
                    characters' Burst rotations can do without 1 or even 2 of
                    their EX’s, filling the remaining time with their standard
                    rotation or alternatively they can act as a mainfielder for
                    a period of time executing their standard rotation against a
                    non-stunned target to build up resources to ensure they have
                    full energy.
                  </li>
                  <li>
                    Both of these options are bad for Corin. Playing her main
                    field outside of Stun isn’t appealing and not having her EX
                    ready immediately during the Stun window is even worse,
                    meaning she needs a lot of off-field Energy Regen or will
                    need to forego using her Burst rotation every time the enemy
                    is Stunned.
                  </li>
                  <li>
                    Corin has high burst damage potential especially in singular
                    boss encounters so we’re raising her up a tier, but her
                    unwieldy kit and weak standard rotation are preventing us
                    from placing her higher until we or the community comes up
                    with a solution.
                  </li>
                </ul>
              </ul>
              <h6>12/07/2024</h6>
              <ul>
                <li>
                  Anomaly characters are currently being used in a far wider
                  variety of teams than in the closed betas with an equally
                  diverse array of combat strategies. Pure Elemental Anomaly,
                  Crazy Disorder reactions or a mixture of both are all seeing a
                  good amount of success in endgame even with just two Anomaly
                  characters currently in the game{' '}
                  <strong>Grace and Piper</strong>. After testing these
                  characters and after numerous conversation with the community,
                  we feel it appropriate to update the tier list and split the
                  DPS into Pure DPS and Anomaly DPS. The fact these characters
                  deal their damage differently, stack different offensive stats
                  and prefer different team compositions all on top of actually
                  playing differently in the combat means we think it best they
                  be moved to their own category on the tier list. You can find
                  a breakdown of what the Anomaly Category entails in the Tier
                  list Criteria - Grace and Piper’s ratings have been updated
                  with this in mind.
                </li>
                <li>
                  <strong>[Soukaku]</strong> T0.5{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T0. Soukaku has the strongest offensive generic buff in the
                  game and excellent specialized elemental buffs, boosting up
                  the current strongest element Ice, but with the downside of
                  having a somewhat awkward ramp-up time and a slow Chain Attack
                  which can eat into valuable Stun time on bosses. A week after
                  ZZZ’s release it’s clear now though that these slight
                  downsides are easy enough to play around and in some cases
                  even can be completely nullified. On top of this, Soukaku is
                  seeing use even outside of her Ice niche thanks to the raw
                  Flat ATK she offers without the need to activate her
                  additional team bonus giving even Lucy a run for her money
                  with a Vortex buffed Fly the Flag buff (Lucy grants 600 ATK,
                  Soukaku grants 1000 ATK). For these reasons we’re raising her
                  to T0.
                </li>
                <li>
                  <strong>[Nicole]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. The community feels Nicole is rated too low for how many
                  teams she finds use in and how smooth she feels to play - and
                  we agree! Nicole specializes in finishing off a teams chain
                  attack sequence with her own, taking up the least amount of
                  field time in the game (and it’s not even close) after which
                  she can immediate enable a Quick Assist - your damage dealer
                  can then take advantage of to execute their Burst Damage Combo
                  while the enemy is fully debuffed by Nicole. This is a very
                  strong interaction and even if Nicole’s debuffs can’t be
                  active for a full Stun window, they’ll still be there for a
                  good chunk of it offering large damage gains. Nicole also has
                  great future prospects with Zhu Yuan - an upcoming Ether Pure
                  DPS. For these reasons we’re moving her up but will also be
                  revisiting her after Zhu Yuan releases.
                </li>
                <li>
                  We're also looking into <strong>[Corin]</strong>. She calcs
                  really well, but her kit only lets her shine during the burst
                  phase when enemy is stunned. So for now we have left her where
                  she landed on release and we will perform some additional
                  testing. Similar thing with <strong>[Anton]</strong> and{' '}
                  <strong>[Billy]</strong> - their calculations and testing will
                  be finished soon and we will take another look at their
                  ratings.
                </li>
              </ul>
              <h6>11/07/2024</h6>
              <ul>
                <li>
                  <strong>[Anby]</strong> T2{' '}
                  <FontAwesomeIcon
                    icon={faArrowUp}
                    width="18"
                    className="arrow-green"
                  />{' '}
                  T1. Stun, specifically main field Stun characters, have proven
                  incredibly strong offensively and defensively in the hands of
                  the community for the endgame Shiyu Defense progression. And
                  when it comes to Main Field stunning characters, Anby finds
                  herself wanted in many teams as she performs exceptionally
                  well, outputting very competitive Daze which allows her to
                  closely compete with Lycaon and Koleda despite only being an
                  A-Rank Agent. For these reasons we're raising Anby up a tier
                  to sit with Koleda. Anby and Koleda play quite differently but
                  we feel Anby has a few stand out traits when comparing the two
                  - namely, greater Dodge flexibility and attack speed all while
                  finding synergies in different teams and keeping up in Daze
                  output. In our eyes this is enough to warrant Anby's move.
                </li>
                <li>
                  <strong>
                    DPS and Support changes to the tier list will happen
                    tomorrow
                  </strong>{' '}
                  as we want to finish our calculations first, but in our draft
                  we're looking at at least 6 characters changing their ratings,
                  moving either up or down.
                </li>
              </ul>
              <h6>03/07/2024</h6>
              <p>Initial release of the tier list.</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/* <div className="banner-bs-promo">
          <h4>Play your mobile games on PC with BlueStacks!</h4>
          <OutboundLink href="https://bstk.me/oPVIwb6Ny" target="_blank">
            <Button variant="primary">Play now</Button>
          </OutboundLink>
        </div> */}
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
        <div className="tier-list-header">
          <h2 className="title">
            Zenless Zone Zero Tier List <span>(Shiyu Defense)</span>
          </h2>
          <h3 className="sub-title">
            You're currently viewing the Zenless Zone Zero{' '}
            <strong>Shiyu Defense</strong> tier list. It shows how the character
            performs in the mode and how easy time they will have clearing it.
          </h3>
        </div>
        <div className="employees-filter-bar zzz">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-zzz">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-header debuffer">
                <FontAwesomeIcon icon={faGavel} width="18" /> Stun
              </div>
              <div className="custom-header support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
            </div>
          </div>
          <div className={`meta-line`}>
            <h5 className="meta-header apex">
              <FontAwesomeIcon icon={faAnglesDown} width="18" /> Apex characters{' '}
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-0 first">
            <div className="tier-rating t-0">
              <span>T0</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter(
                    (emp) =>
                      emp.tierListCategory === 'DPS' ||
                      emp.tierListCategory === 'Anomaly'
                  )
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faGavel} width="18" /> Stun
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierListCategory === 'Stun')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 11)
                  .filter((emp) => emp.tierListCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-05">
            <div className="tier-rating t-05">
              <span>T0.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter(
                    (emp) =>
                      emp.tierListCategory === 'DPS' ||
                      emp.tierListCategory === 'Anomaly'
                  )
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faGavel} width="18" /> Stun
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierListCategory === 'Stun')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 10)
                  .filter((emp) => emp.tierListCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={`meta-line`}>
            <h5 className="meta-header meta">
              <FontAwesomeIcon icon={faAnglesDown} width="18" /> Meta characters{' '}
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-1">
            <div className="tier-rating t-1">
              <span>T1</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter(
                    (emp) =>
                      emp.tierListCategory === 'DPS' ||
                      emp.tierListCategory === 'Anomaly'
                  )
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faGavel} width="18" /> Stun
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierListCategory === 'Stun')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 9)
                  .filter((emp) => emp.tierListCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-15">
            <div className="tier-rating t-15">
              <span>T1.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter(
                    (emp) =>
                      emp.tierListCategory === 'DPS' ||
                      emp.tierListCategory === 'Anomaly'
                  )
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faGavel} width="18" /> Stun
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierListCategory === 'Stun')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 8)
                  .filter((emp) => emp.tierListCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-2">
            <div className="tier-rating t-2">
              <span>T2</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter(
                    (emp) =>
                      emp.tierListCategory === 'DPS' ||
                      emp.tierListCategory === 'Anomaly'
                  )
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faGavel} width="18" /> Stun
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierListCategory === 'Stun')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 7)
                  .filter((emp) => emp.tierListCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={`meta-line`}>
            <h5 className="meta-header niche">
              <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche
              characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-3">
            <div className="tier-rating t-3">
              <span>T3</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter(
                    (emp) =>
                      emp.tierListCategory === 'DPS' ||
                      emp.tierListCategory === 'Anomaly'
                  )
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faGavel} width="18" /> Stun
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierListCategory === 'Stun')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 6)
                  .filter((emp) => emp.tierListCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-4">
            <div className="tier-rating t-4">
              <span>T4</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 5)
                  .filter(
                    (emp) =>
                      emp.tierListCategory === 'DPS' ||
                      emp.tierListCategory === 'Anomaly'
                  )
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faGavel} width="18" /> Stun
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 5)
                  .filter((emp) => emp.tierListCategory === 'Stun')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp.ratings[sortOption] === 5)
                  .filter((emp) => emp.tierListCategory === 'Support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <ZZZCharacter
                            slug={emp.slug}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
      </div>
    </DashboardLayout>
  );
};

export default ZZZTierList;

export const Head: React.FC = () => (
  <Seo
    title="Tier list (ZZZ) | Zenless Zone Zero | Prydwen Institute"
    description="Use our Tier List to discover the best A-Rank and S-Rank characters in Zenless Zone Zero (ZZZ)."
    game="zzz"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulZzzCharacter(
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        unitId
        slug
        name
        element
        rarity
        faction
        style
        tierListCategory
        tierListTags
        ratings {
          shiyu
        }
      }
    }
  }
`;
